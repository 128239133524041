import React from 'react';
import {
  Container,
  Grid,
  Typography,
} from '@material-ui/core'
import { Link, graphql } from 'gatsby'
import Layout from "../../components/layout"
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { createContent } from "../../common/Theme"
import FadeIn from "../../components/FadeIn"
import ProjectsLink from "../../components/projectsLink"
import ContactUs from "../../components/contactUs"

import '../../assets/styles/pages/services.scss'

const DevelopmentPage = ({ data: { allMarkdownRemark: { edges } } }) => {
  const content = createContent();

  return (
    <Layout>
      <section className='services-hero-section' id='development-hero-section'>
        <Container classes={{root: content.content}}>
          <div className='services-hero-container'>
            <div className='services-hero-title'>
              <Typography className='text-tag' variant='body2' color='textSecondary'>What we do</Typography>
              <Typography variant='h1' color='textSecondary'>Development</Typography>
            </div>
            <div className='services-hero-image' id='development-hero-image' />
          </div>
        </Container>
      </section>
      <section id='development-details-section'>
        <Container classes={{root: content.content}}>
          <Grid container spacing={10}>
            <Grid item md={6} sm={6} xs={12}>
              <FadeIn>
                <Typography variant='h3'>Software Development</Typography>
                <Typography>
                  We develop custom software solutions that solve business challenges.
                  We start from the challenge, devise a strategy, then develop and implement
                  a custom software solution needed to solve the challenge.
                </Typography>
              </FadeIn>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <FadeIn>
                <Typography variant='h3'>Mobile Apps</Typography>
                <Typography>
                  We create cross platform mobile apps that are designed to run on different mobile platforms and devices using a single codebase.
                  By leveraging the powerful features mobile devices have to offer we deliver high-performing mobile apps that meet
                  your specific business needs.
                </Typography>
              </FadeIn>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <FadeIn>
                <Typography variant='h3'>Content Management Systems</Typography>
                <Typography>
                  In a dynamic digital landscape the content and information companies distribute need to be as dynamic.
                  That's why we help companies by implementing content management systems like Wordpress and headless CMS
                  which give businesses complete control over their content and an easy way to access, update and deploy it.
                </Typography>
              </FadeIn>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <FadeIn>
                <Typography variant='h3'>API Integrations</Typography>
                <Typography>
                  In a world that's becoming increasingly more connected you don't want your software to fall behind.
                  We help in creating modern API's for your software projects and by connecting them to other tools and products
                  you want them to integrate with.
                </Typography>
              </FadeIn>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <FadeIn>
                <Typography variant='h3'>Cloud Engineering</Typography>
                <Typography>
                  Cloud computing is revolutionizing IT and provides us with new and exciting possibilities to build software
                  systems and infrastructure. We help businesses harness these possibilities, implementing and migrating
                  your legacy systems to cloud services like Amazon Web Services, Microsoft Azure and Google Cloud Platform.
                </Typography>
              </FadeIn>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section id='related-cases-section'>
        <Container classes={{root: content.content}}>
          <Typography variant='h3' color='textSecondary'>Related projects</Typography>
          <Grid container spacing={6}>
            {
              edges.map(edge => (
                <Grid item md={6} key={edge.node.id}>
                  <ProjectsLink
                    slug={edge.node.frontmatter.slug}
                    title={edge.node.frontmatter.title}
                    image={edge.node.frontmatter.featuredImage}
                    categories={edge.node.frontmatter.categories}
                    light
                  />
                </Grid>
              ))
            }
          </Grid>
          <Typography variant='h3' color='textSecondary'>Other services</Typography>
          <Link to='/services/design' className='other-services-link'>
            <span>Design</span>
            <ArrowRightAltIcon />
          </Link>
          <Link to='/services/consulting' className='other-services-link'>
            <span>Consulting</span>
            <ArrowRightAltIcon />
          </Link>
        </Container>
      </section>

      <ContactUs mt />
    </Layout>
  )
}

export default DevelopmentPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { slug: { in: ["/projects/safety-reporting", "/projects/inventory-management"] } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            slug
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
